.react-phone-number-input__country-select
{
    position : static;
	top      : auto;
	left     : auto;
	height   : 100%;
	width    : 100%;
	max-width: 140px;
	z-index  : 1;
	border   : 0;
	opacity  : 1;
	cursor   : pointer;
}

.react-phone-number-input__icon
{
	/* @@Accessibility: Show icon ontop of country select */
    position: static;
    margin: 0 4px 0 0;
}

.ReactPasswordStrength .ReactPasswordStrength-input {
	width: 100%;
	text-indent: 14px;

	&::placeholder {
		color: #bcc3ce;
		font-size: 1em;
	}
}

.ReactPasswordStrength  .ReactPasswordStrength-strength-desc {
	top: 100%;
	padding: 4px 0;
	font-size: 80%;
	width: 80%;
}

[aria-haspopup="true"]:focus-within .menu {
    display: block;
}

.accessible-phone-input {
	padding-left: 24px;
	select {
		background-color: white;
	}
}

.accessible-phone-input .accessible-form-group label {
    top: -14px;
}

input:invalid, input:focus:invalid {
	border-left:5px solid salmon;
}

.form-group {
	.has-icon-left .form-icon {
		left: 2px;
	}
}

a[target=_blank]:focus {
	position: relative;
}

a[target=_blank]:focus:after {
	content:'Opens in a new window';
	position: absolute;
	top: 100%;
	min-width: 100%;
	left: 0;
	color: #333;
}