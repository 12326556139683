.accessible-phone-input {
  display: flex;
  flex-direction: row;
}

.accessible-phone-input .accessible-country-list {
  display: flex;
  margin-right: 9px;
  flex: 1;
  max-width: 100%;
  min-width: 180px;
}

.accessible-phone-input select {
  max-width: 100%;
  width: 100%;
}

.accessible-phone-input input {
  max-width: 100%;
  width: 100%;
}

.accessible-phone-input .international-dial-code {
  flex: 1;
  min-width: 24px;
  max-width: 52x;
  display: flex;
}

.accessible-phone-input .accessible-phone-number {
  display: flex;
  flex: 5;
}

.accessible-phone-input .accessible-form-group {
  position: relative;
}

.accessible-phone-input .accessible-form-group label {
  position: absolute;
  top: -10px;
  font-size: 10px;
  left: 0;
}

.accessible-form-group .error {
  position: absolute;
  top: 100%;
  font-size: 10px;
  left: 0;
  color: red;
}
